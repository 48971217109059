<!-- Settings -->
<template>
    <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">
          <div class="row">
            <div class="col-sm-5 col-xs-12">
              <b-form-group class="mb-3">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"                  
                    auto-complete="nope"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter=''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>   
             <div class="col-sm-3">
              <!--el-select placeholder="Select Category" v-model="selectedCategory" @change="getCategoryvalues()" class="website-based-list">
                  <el-option value="" label="All Category"></el-option>
                  <el-option v-for="(category, index) in category_list" :key="index" :label="category.label" :value="category.id">
                  </el-option>
              </el-select-->

              <el-select  :value="treeLabel"  clearable placeholder="select category" ref="selectTree1" 
                    @clear="categoryClear()"
                    :disabled="this.$route.params.product_id">                      
                        <el-option :value="treeLabel" :label="treeLabel" class="tree_option">                                     
                              <el-tree                                       
                              class="filter-tree"
                              :data="category"                                       
                              :props="defaultProps"                                   
                              node-key="id"
                              :expand-on-click-node="false" 
                              @current-change="SelectCategory"
                              :default-expanded-keys ="expand"                                                                                                             
                              :highlight-current="true"  
                              @node-click="handleNodeClick"                                                                                                                                       
                              ref="tree">
                              <span class="custom-tree-node" slot-scope="{data }">
                                <span class="icon-folder">                                          
                                <i class="el-icon-folder el-fw"  aria-hidden="true"></i>
                                <span class="icon-folder_text"> {{ data.label }}</span>
                                </span>
                              </span>
                              </el-tree>
                              
                        </el-option>
                    </el-select>

            </div>        
            <div class="col-sm-4 col-xs-12 text-right">              
              <b-button class="btn-sm btn-filter rounded-3" v-b-toggle.collapse-1 variant="primary" id="tooltip-button-variant"><i class="fa fa-filter" aria-hidden="true"></i></b-button>
               <b-tooltip target="tooltip-button-variant" variant="primary">Column Filter</b-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 select-website">
            <b-collapse id="collapse-1" class="mb-3">
              <b-card>
                <p class="card-text">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select All</el-checkbox>
                </p>
                <hr/>
                <el-checkbox-group v-model="checkedColumns" @change="handleCheckedCitiesChange" >
                    <el-checkbox v-for="(column,index) in column_list" :label="column.value" :key="index">{{column.name}}</el-checkbox>
                </el-checkbox-group>
              </b-card>
            </b-collapse>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">  
              </div>
            </div>
          </div>   
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column  fixed label='SKU' prop="sku" sortable width="100" v-if="showAndHideColumn('sku')" align="center">
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewProduct(scope.row.id)">{{(scope.row.sku) ? scope.row.sku : ""}}</a></el-tag>
                </template>
              </el-table-column>
               <el-table-column label="Status"  width="100" v-if="showAndHideColumn('status')" align="center">
                <template slot-scope="scope">
               <el-switch :active-value="1" :inactive-value="0"  v-model="scope.row.record_status"  @change="updateStatus(scope.row.id, scope.row.record_status)" style="margin:0px;"></el-switch>                                       
                </template>
              </el-table-column>  
              <el-table-column label="Product Name"  :min-width="200" v-if="showAndHideColumn('product_name')">
                <template slot-scope="scope">
                <span>{{(scope.row.name) ? scope.row.name : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Category Name"  width="150" v-if="showAndHideColumn('category_name')">
                <template slot-scope="scope">
                <span>{{(scope.row.category_name) ? scope.row.category_name: ""}}</span>
                </template>
              </el-table-column> 
               <el-table-column label="Cost Price($)" width="120"  align="center" v-if="showAndHideColumn('cost_price')">
                <template slot-scope="scope">
                   <el-input-number size="small"
                     :precision="2" 
                      style="text-align:center" :controls = false
                      v-model="scope.row.cost_price" controls-position="right"  placeholder="$ 0.00" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>                
                </template>
              </el-table-column>   
               <el-table-column label="Regular %"  width="100"  align="center">
                <template slot-scope="scope">
                  <el-input-number size="small"
                      style="text-align:center" :controls="false"
                      v-model="scope.row.regular_per" controls-position="right"  placeholder="%" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>                
                </template>
              </el-table-column>           
               <el-table-column label="Regular($)"  width="100"  align="center" v-if="showAndHideColumn('regular_price')">
                <template slot-scope="scope">
                  <span>$ {{parseFloat(scope.row.regular_price).toFixed(2)}}</span>
                </template>
              </el-table-column>   
                <el-table-column label="Sale Price($)"  width="120"  align="center" v-if="showAndHideColumn('sale_price')">
                <template slot-scope="scope">
                  <el-input-number size="small"
                      :precision="2" 
                      style="text-align:center" :controls = false
                      v-model="scope.row.special_price" controls-position="right"  placeholder="$ 0.00" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>               
                </template>
              </el-table-column>   
               <el-table-column label="Contractor %"  width="120"   align="center" >
                <template slot-scope="scope">
                  <el-input-number size="small"
                      style="text-align:center" :controls = false
                      v-model="scope.row.contractor_discount" controls-position="right"  placeholder="%" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>               
                </template>
              </el-table-column>           
               <el-table-column label="Contractor($)" width="120"  align="center" v-if="showAndHideColumn('contractor_price')">
                <template slot-scope="scope">
                  <span>$ {{parseFloat(scope.row.contractor_price).toFixed(2)}}</span>
                </template>
              </el-table-column> 
               <el-table-column label="Wholesale %"  width="100" align="center" >
                <template slot-scope="scope">
                   <el-input-number size="small"
                      style="text-align:center" :controls = false
                      v-model="scope.row.whole_discount" controls-position="right"  placeholder="$ 0.00" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>                
                </template>
              </el-table-column>
               <el-table-column label="Wholesale Min Price"  width="120"  align="center" >
                <template slot-scope="scope">
                  <el-input-number size="small"
                      :precision="2" 
                      style="text-align:center" :controls = false
                      v-model="scope.row.whole_min_amount" controls-position="right"  placeholder="$ 0.00" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>  
                </template>
              </el-table-column> 
               <el-table-column label="Wholesale Qty"  width="130"  align="center" >
                <template slot-scope="scope">
                  <span>{{scope.row.whole_quantity}}</span>
                </template>
              </el-table-column>
              <el-table-column label="Wholesale($)"  width="120"  align="center" v-if="showAndHideColumn('whole_amount')">
                <template slot-scope="scope">
                  <span>$ {{parseFloat(scope.row.whole_amount).toFixed(2)}}</span>
                </template>
              </el-table-column>  
               <el-table-column label="Dealer %"  width="100"   align="center" >
                <template slot-scope="scope">
                  <el-input-number size="small"
                      style="text-align:center" :controls = false
                      v-model="scope.row.dealer_discount" controls-position="right"  placeholder="%" 
                      @change="getPriceCalculation(scope.$index, scope.row)"></el-input-number>
                </template>
              </el-table-column>             
               <el-table-column label="Dealer($)"  width="100" align="center" v-if="showAndHideColumn('dealer_amount')">
                <template slot-scope="scope">
                  <span>$ {{parseFloat(scope.row.dealer_amount).toFixed(2)}}</span>            
                </template>
              </el-table-column>   
               <el-table-column label="Unit Type"  width="100" v-if="showAndHideColumn('unit')" >
                <template slot-scope="scope">
                <span>{{(scope.row.unit) ? scope.row.unit : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Min Qty"  width="100"  align="center" v-if="showAndHideColumn('min_quantity')">
                <template slot-scope="scope">
                <span>{{(scope.row.min_quantity) ? scope.row.min_quantity : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Max Qty"  width="100" align="center" v-if="showAndHideColumn('max_quantity')">
                <template slot-scope="scope">
                <span>{{(scope.row.max_quantity) ? scope.row.max_quantity : ""}}</span>
                </template>
              </el-table-column>
              <el-table-column label="In-Stock"  width="100"  align="center" v-if="showAndHideColumn('stock_quantity')">
                <template slot-scope="scope">
                <span>{{(scope.row.stock_quantity) ? scope.row.stock_quantity : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="Re-order"  width="100"  align="center" v-if="showAndHideColumn('reorder')">
                <template slot-scope="scope">
                <span>{{(scope.row.reorder) ? scope.row.reorder : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column label="sq.ft" width="80" v-if="showAndHideColumn('squre_feet')" align="center">
                <template slot-scope="scope">
                <span>{{(scope.row.sq_feet) ? scope.row.sq_feet : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column   label='Truck/Parcel' prop="transport_type"  width="100" v-if="showAndHideColumn('truck_parcel')" align="center">
                <template slot-scope="scope">
                <span>{{(scope.row.transport_type) ? scope.row.transport_type : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column   label='Location/Stock' prop="stock_location"  width="120" v-if="showAndHideColumn('stock_location')">
                <template slot-scope="scope">
                <span>{{(scope.row.stock_location) ? scope.row.stock_location : ""}}</span>
                </template>
              </el-table-column>
               <el-table-column   label='Order' prop="order_type"  width="120" v-if="showAndHideColumn('order_type')">
                <template slot-scope="scope">
                <span>{{(scope.row.order_type) ? scope.row.order_type : ""}}</span>
                </template>
              </el-table-column>    
              <el-table-column label="Brand Name"  width="130" v-if="showAndHideColumn('brand_name')">
                <template slot-scope="scope">
                <span>{{(scope.row.brand_model) ? scope.row.brand_model : ""}}</span>
                </template>
              </el-table-column>        
                          
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
        <v-dialog/>
    </div>
  </div>   
</div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllproducts, deleteProduct, changeStatus,getCategoryTree} from "@/api/product";
import { globalEvents } from '@/utils/globalEvents'
import UpdateInventoryQuantities from '@/components/UpdateInventoryQuantities';
import Form from "@/core/services/api.form.services";
import ProductCostChange from "@/components/ProductDashboard/productChangeCost";
//import _ from 'lodash';
  export default {
    name: "products",
    data() {
      return {
        list: [],
        items: [],
        category:[],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 50,
        },       
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectedCategory: null,
        isShowClearIcon : false,
        filterOn: [],
        treeLabel:"",
        form: new Form({
         
        }),
         column_list:[
         
          {
            name:"SKU",
            value:'sku'
          },
          {
            name:"Product Name",
            value:'product_name'
          },
          {
            name:"Category Name",
            value:'category_name'
          },
                            
          {
            name:"Cost Price",
            value:'cost_price'
          },
           
         
           {
            name:"Regular Price",
            value:'regular_price'
          },
          {
            name: "Sale Price",
            value: 'sale_price'
          },
          
           {
            name:"Contractor Price",
            value:'contractor_price'
          },
             
           {
            name:"Dealer Price",
            value:'dealer_amount'
          }, 
          
           {
            name:"Wholesale Price",
            value:'whole_amount'
          },           
           {
            name:"Brand Name",
            value:'brand_name'
          }, 
           
           {
            name:"Stock Location",
            value:'stock_location'
          },  
          
          {
            name: "Status",
            value:'status'
          },
          {
            name: "Min Qty",
            value:'min_quantity'
          },
          {
            name: "Max Qty",
            value:'max_quantity'
          },
          {
            name: "In Stock",
            value:'stock_quantity'
          },
          {
            name: "Reorder",
            value:'reorder'
          },
         
         ],
        checkAll:false,
        isIndeterminate: true,
        category_list : [],
        checkedColumns:[
            'product_id',
            'product_name',
            'sku',
            'category_name',
            'cost_price',  
            'regular_price',
            'sale_price',                    
            'stock_quantity',            
            'status',
            'dealer_amount',
            'contractor_price',
            'whole_amount',

        ],
       defaultProps: {
        children: "children",
        label: "label",
       },
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1);
        }
      }
    },
    computed: {
    },

    components: {
    },

    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },      
        {
        title: "Product List",
        route: "/product/list",
      },
      {
        title: "Product Dashboard",
      },
      ]);
      globalEvents.$on('priceChange', added => {
        this.fetchData(1)
      if (added ==1) {
        this.fetchData(1)
      }
    })
    },
    created(){
      this.fetchData(1);
      this.getCategory();
      globalEvents.$on('InventoryUpdated', added => {
      if (added) {
        this.fetchData(1)
        this.$showResponse('success', 'Inventory details updated successfully.');
      }
    })
    },
    methods:{
      clearShow(){
         this.isShowClearIcon = false
        if(this.filter){
          this.isShowClearIcon = true
        }
      },

    SelectCategory(currentObj) {
      this.selectedCategory = currentObj.id;
     // this.form.category_name = currentObj.label; 
      this.fetchData(1);
    },
    handleNodeClick (node) {     
      this.treeLabel = node.label
      this.$refs.selectTree1.blur() 
    },
      ImportProduct(){
         this.$router.push({path:'/product/imports'})
      },
       showAndHideColumn(column_name){
        return this.$isColumnShow(column_name, this.checkedColumns);
      },
      updateStatus(product_id,status){
        changeStatus(product_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })
      },
       handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },

      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
         if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }

         if(this.selectedCategory){
          params.selected_category = this.selectedCategory;
        }
        getAllproducts(params).then(response => {
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })

      },
       categoryClear(){     
	      this.treeLabel = '';
	      this.selectedCategory = '';      
	      this.expand = [];
	      this.fetchData(1);
	    },
	    getCategory() {
	      this.loading = true;
	      getCategoryTree().then((response) => {       
	        this.category = response.data.data;
	        this.loading = false;
	      });
	    },
      getCategoryvalues(){
        this.fetchData(1)
      },
      addProduct(){
        this.$router.push({path:'/product/create'})
      },
       handleCheckAllChange(val) {        
          this.checkedColumns = [];
          if(val){
              this.column_list.map((column) =>{
                  this.checkedColumns.push(column.value);              
              });
          }
          this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {         
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.column_list.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      viewProduct(product_id){
         this.$router.push({path:'/product/view/'+product_id})
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/product/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/product/edit/'+row.id})
          }
          else if (evt === 3){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
                deleteProduct(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
          else if (evt === 4){
            this.$modal.show(UpdateInventoryQuantities, {
              inventory: row,
              }, { draggable: true, transition: 'pop-out', height: 'auto', clickToClose: true, adaptive: true , width: '450px' });
          }
        },
        notificationMessage(field_name){
          const h = this.$createElement;
             
             this.$notify({
               message: h(
                 "i",
                 {
                   style: "color: red",
                 },
                 "Please enter " + field_name 
               ),
               offset: 200,
               type: "warning",
             });
        },
       
         getPriceCalculation(index,row) {
           
            let product = [];
            product = row;
            product.change_supplier_cost = 0;
            if(!product.cost_price){
              this.notificationMessage('cost price')
              this.fetchData(1);
            }else if(!product.regular_per){
              this.notificationMessage('regular percentage')
              this.fetchData(1);
            }else{ 
            const regular_price = parseFloat(product.cost_price) + parseFloat(product.cost_price * (product.regular_per / 100));
            var dealer = parseFloat(product.cost_price) + parseFloat(product.cost_price * (product.dealer_discount / 100));
            var contract = regular_price - product.regular_price * (product.contractor_discount / 100);
            var whole = regular_price - product.regular_price * (product.whole_discount / 100);
            if(!product.contractor_discount){
              contract = 0;
            }
            if(!product.dealer_discount){
              dealer = 0;
            }
            if(!product.whole_discount){
              whole = 0;
            }
            
            product.regular_price = "";
           
            if (product.regular_per && product.cost_price) {
              product.regular_price = parseFloat(regular_price).toFixed(2);
            }
            product.dealer_amount = 0.00;
            if (product.dealer_discount && product.cost_price) {
              product.dealer_amount = parseFloat(dealer).toFixed(2);
            }

            product.contractor_price = 0.00;
            if (
              product.contractor_discount &&
              product.regular_per &&
              product.cost_price
            ) {
              product.contractor_price = parseFloat(contract).toFixed(2);
            }
           
            product.whole_amount = 0.00;
            if (
              product.whole_discount &&
              product.regular_per &&
              product.cost_price
            ) {
              product.whole_amount = parseFloat(whole).toFixed(2);
            }
            product.whole_quantity = Math.round(product.whole_min_amount/product.regular_price);
            this.list[index] = product; 
            this.$modal.show(
              ProductCostChange,
              {products:product,product_id:product.id},
              {
                draggable: true,
                transition: "pop-out",
                height: "auto",
                clickToClose: true,
                adaptive: true,
                width: "450px",
              }
            );
        }      
    },
    }
  }
</script>
<style scoped>
.add_upc {
  position: absolute;
}
.el-scrollbar__view .el-select-dropdown__list{
  padding-bottom: 0px !important;
}
.tree_option {
    overflow-y: scroll;
    height: auto;
    padding: 10px 10px;
}

.add_option{
    padding: 0px;
    overflow-y: unset;
}
.min-input{  
  width: 60%;
}
.el-table td.el-table__cell div{
  width: 80% !important;
}
</style>