<template>
    <div>
      <div slot="header" class="modal-header popup-header">
        <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>{{label.title}}</span>
                <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
            </div>
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Employees-->
                <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                    <!--begin: Employees Body-->
                    <div class="row justify-content-center ">
                        <div class="col-xl-12">
                            <!--begin: Wizard Form-->
                             <el-form ref="form" :model="form" auto-complete="nope">
                                <!--begin: Wizard Step 1-->
                                <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                    <div class="row">
                                      <div class="col-xl-2" v-if="this.form.main_supplier.supplier_cost != this.form.cost_price">
                                        <el-checkbox
                                          class="temp-check"
                                          true-label="1"
                                          false-label="0"
                                          v-model="form.change_supplier_cost"
                                          label="Change Main Supplier Cost"
                                        ></el-checkbox>
                                      </div>
                                      <div class="col-xl-12" v-if="form.change_supplier_cost == 1">
                                        <div class="d-flex flex-wrap mb-3 mt-3" v-if="this.form.main_supplier">
                                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Supplier Name : <b>{{this.form.main_supplier.supplier_name}}</b></span>
                                        </div>
                                        <div class="d-flex flex-wrap mb-3 mt-3" v-if="this.form.main_supplier">
                                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Supplier Old Cost : <b>{{this.form.main_supplier.supplier_cost}}</b></span>
                                        </div>
                                        <div class="d-flex flex-wrap mb-4 " v-if="this.form.main_supplier">
                                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">Supplier New Cost : <b>$ {{parseFloat(this.form.cost_price).toFixed(2)}}</b></span>
                                        </div>
                                        </div>
                                        <div class="col-xl-12 mt-5">
                                          <b>Are you sure want to change product cost ?</b>
                                        </div>                                   
                                    </div>
                                </div>    
                                <!--begin: Employees Actions -->
                                <div class="d-flex justify-content-between border-top pt-10" style="float:right">
                                    <a  @click="changePrice" class="text-white">
                                        <div class="btn btn-primary font-weight-bold text-uppercase">
                                            Yes
                                        </div>
                                    </a>
                                    <a  @click="closeForm" class="text-white ml-5">
                                        <div class="btn btn-danger font-weight-bold text-uppercase">
                                            No
                                        </div>
                                    </a>
                                </div>
                                <!--end: Employees Actions -->
                            </el-form>
                            <!--end: Employees Form-->
                        </div>
                    </div>
                    <!--end: Employees Body-->
                </div>
            </div>
            <!--end: Employees-->
        </div>
    </div>
    </template>
    
    <script>
    import Form from '@/core/services/api.form.services'
    import {globalEvents} from '@/utils/globalEvents';
    export default {
      name: 'create-term',
      props: {
        product_id: {
          type: Number,
          required: true
        },
        Products: {
          type: Object,
          required: true
        },
        label:{
          type: Object,
          default() {
            return {button:'Create',title:'Please Confirm'}
          }
          }
      },
      data() {
        return {
          loading : false,
          error:null,
          main_supplier:[],
          form : new Form({
            change_supplier_cost:"0",
            product_id : null,
            main_supplier_id : null
          }),
           
           submit_btn_text: 'Add Term',
        }
      },
      methods: {
        reset() {
          this.form.reset()
          this.$refs['form'].resetFields()
        },
        closeForm() {
          if (!this.form.submiting) {
            this.$emit('close');
            globalEvents.$emit('priceChange',1)
          }
        },
        changePrice() {      
          this.loading=true;
          this.api_url = "product/edit/dashboard/" + this.product_id;       
          this.form
            .post(this.api_url)
            .then((response) => {
              if (response.status) {
                this.$router.push({
                    path: "/product/dashboard",
                  });
                this.loading = false;
                this.$emit('close')  
                this.$showResponse("success", response.message);
                globalEvents.$emit('priceChange',1)
              } else {
                this.loading = false;
                this.$showResponse("warning", response.message);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        }
      },
      created() {
        this.form = new Form(this.Products);
        console.log(this.Products);
      },
        mounted() {
            if(this.supplier_id){
                this.form.supplier_id = this.supplier_id
            }
        }
    }
    
    </script>
    
    <style scoped>
    .option-info{
      float: left; color: #8492a6; font-size: 13px; clear:both;line-height: 20px;
    }
    .option-info i {
      color: #27304A;
    }
    .title{
      font-size: 16px;
      font-weight: 600;
      color: #27304A;
      display: inline-block;
      margin-bottom: 20px;
    }
    .event-container {
      background-color: white;
      padding: 20px;
      border: 1px solid #ebeef5;
    }
    .icon-pointer{
      font-size: 20px; cursor: pointer;color: #616e8e;
    }
    </style>
    